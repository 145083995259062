// @import-normalize;

@config "./tailwind.config.cjs";

@tailwind components;
@tailwind utilities;

@import 'react-toastify/dist/ReactToastify.min.css';
@import '../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

:root {
  // --toastify-color-light: #fff;
  --toastify-color-dark: #191C1C;
  --toastify-color-info: var(--md-sys-color-primary-inverse);
  --toastify-color-success: var(--md-sys-color-primary);
  --toastify-color-warning: var(--md-sys-color-tertiary);
  --toastify-color-error: var(--md-sys-color-error);
  // --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-color-success: var(--md-sys-color-primary);
  --toastify-color-warning: var(--md-sys-color-tertiary);
  --toastify-color-error: var(--md-sys-color-error);
}