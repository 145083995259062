@import '../../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.card {
  min-width: 200px;
  width: 100%; 
  padding: 16px !important;
  cursor: pointer;
}

.card.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card:not(.loading) {
  display: grid;
  gap: 8px;
  grid-template-columns: min-content 1fr;
  grid-template-areas:
    "companyName companyName icons"
    "country recipientName icons"
    "tags tags tags";
}

.icons {
  margin: -12px -8px;
  grid-area: icons;
}

.alertIcons {
  display: flex;
  align-items: center;
  margin: 0 2px;
}

.options {
  & > * + * {
    margin: 0 -6px;
    padding: 0;
  }
  display: flex;
  align-items: center;
}

.companyName {
  grid-area: companyName;
  @extend .body-small;
  color: var(--md-sys-color-outline);
}

.recipientName {
  grid-area: recipientName;
  @extend .title-medium;
}

.country {
  grid-area: country;
}

.countryAvatar {
  width: 32px;
  height: 32px;
  background-size: cover;
  background-position: center;
  border-radius: 32px;
  background-color: var(--md-sys-color-disabled);
  // box-shadow: var(--md-sys-elevation-level1);
  border: 1px solid var(--md-sys-color-disabled);
}

.tags {
  grid-area: tags;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
}

.chip {
  border-color: var(--md-sys-color-disabled) !important;
  padding: 0 4px !important;

  span:last-child {
    @extend .body-small;
    font-weight: 300;
    white-space: wrap;
  }
}

.alert {
  --md-sys-color-surface: var(--md-sys-color-tertiary-container);
  --md-sys-color-surface-variant: var(--md-sys-color-container);
}

.deletedEmployee {
  background-color: #ffcccc !important;
}

.addressVerifiedSuccess {
  color: var(--md-sys-color-primary);
}

.addressVerifiedFail {
  color: var(--md-sys-color-error);
}

.addressVerifiedWarning {
  color: var(--md-sys-color-tertiary);
}