@import '../../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
}

.supportText {
  @extend .body-medium;
}

.secondStep {
  padding-top: 24px;
}

.subTitle {
  @extend .title-medium;
  margin-bottom: 24px;
}

.paragraph {
  @extend .body-medium;
  margin-bottom: 24px;
}

.subTitle + .paragraph {
  margin-top: -16px;
}

.mappingSettingsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  padding-bottom: 24px;
}

.headerMappingRow, .officeMappingRow {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 24px;
}

.headerMappingRow + .headerMappingRow, .officeMappingRow + .officeMappingRow {
  margin-top: 16px;
}

.headerMappingRowText, .officeMappingRowText {
  @extend .body-medium;
}

.errorMessage {
  @extend .body-medium;
  width: 100%;
  text-align: center;
}

.reviewExpandable + .reviewExpandable, .reviewSubTitle + .reviewExpandable, .reviewExpandable + .reviewSubTitle {
  margin-top: 16px;
}

.reviewSubTitle {
  @extend .title-medium;
}

.errorMessage {
  white-space: break-spaces;
  display: block;
  text-align: left;
}

.table {
  th, td {
    padding: 4px 8px !important;
  }
}

.empty {
  @extend .body-medium;
  width: 100%;
  text-align: center;
}

.formToggle {
  display: flex;
  padding-bottom: 24px;
}

.flexGrow {
  flex-grow: 1;
}